import React, {useCallback, useState} from 'react';
import Page from '../../../layout/Page/Page';
import CustomTable from '../../../components/table/CustomTable';
import {useFiltersPR} from '../../../components/providers/FiltersProvider';
import useFetch from '../../../hooks/useFetch';
import {ActionIcon, Switch, Tooltip} from 'rizzui';
import {Link} from 'react-router-dom';
import {MdOutlineModeEditOutline} from "react-icons/md";
import moment from 'moment';
import {menuRoutes} from '../../../router/menu';
import {toast} from 'sonner';
import ProductsFilters from './ProductsFilters';
import {useSelector} from 'react-redux';
import {RootState} from '../../../redux/store';
import {FilterDrawerView} from '../../../components/table/components/TableFilter';
import {ProductsApiResponse} from "../../../type/entities/product-type";
import {ProductService} from "../../../services/product/productService";
import DeletePopover from "../../../components/buttons/DeletePopover";

interface ProductsListProps {
}

const columnOrder = [
    'id',
    'name',
    'code',
    'description',
    'weeklyHours',
    'amount',
    'active',
    'createdAt',
    'updatedAt',
];

const ProductsList: React.FC<ProductsListProps> = () => {

    const {user} = useSelector((state: RootState) => state.auth);
    const {filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters} = useFiltersPR();
    const service = new ProductService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listProducts(filters);
        return response.getResponseData() as ProductsApiResponse;
    }, [filters]));

    const toggleStatus = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleProductStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el producto" : "Se ha activado el producto");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        await service.deleteProduct(id);
        refetch();
    };

    const handleMultiDelete = async (ids: string[]) => {
        await service.deleteMultiProducts(ids);
        refetch();
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });


    return (
        <Page container='fluid'>
            {data !== undefined && (
                <CustomTable
                    id={'products-table'}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    columnsNotShown={[]}
                    overrideColumns={[
                        {
                            key: 'createdAt',
                            render: (row: any) => {
                                const date = row.row.original?.createdAt?.date;
                                return date ? moment(date).format('DD/MM/YYYY') : 'N/A';
                            },
                        },
                        {
                            key: 'updatedAt',
                            render: (row: any) => {
                                const date = row.row.original?.updatedAt?.date;
                                return date ? moment(date).format('DD/MM/YYYY') : 'N/A';
                            },
                        },
                        {
                            key: 'active',
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={row.row.original.active ? 'Desactivar producto' : 'Activar producto'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Switch
                                            id={row.row.original.id}
                                            checked={row.row.original.active}
                                            disabled={changingStatus.includes(row.row.original.id) || row.row.original.id === user?.id}
                                            onChange={() => toggleStatus(row.row.original.id, row.row.original.active)}
                                        />
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    actions={[
                        {
                            label: 'Edit',
                            permissions: {group: 'products', permission: 'edit_products'},
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar producto'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.products.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20}/>
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: {group: 'products', permission: 'delete_products'},
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar producto`}
                                                description={`¿Estás seguro de que deseas eliminar el ${row.name}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as ProductsApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                />
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Productos'}>
                <div className="grid grid-cols-1 gap-6">
                    <ProductsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters}/>
                </div>
            </FilterDrawerView>
        </Page>
    );
};

export default ProductsList;