import React, { Fragment, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Role, PermissionGroup, PermissionsApiResponse, RolePermission } from '../../type/entities/role-type';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import FormGroup from '../../layout/shared/form-group';
import Page from '../../layout/Page/Page';
import FormFooter from '../_layout/_footers/form-footer';
import Spinner from '../../components/bootstrap/Spinner';
import RequiredInput from '../../components/forms/InputHOC';
import useFetch from '../../hooks/useFetch';
import { PermissionService } from '../../services/auth/permissionService';
import { PermissionsGrid } from '../../components/ui/PermissionsAccordion';
import { Input } from 'rizzui';
import { PopoverPicker } from '../../components/forms/PopoverPicker';

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    data?: Role | undefined;
}

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(100, 'Demasiado largo').required('Campo obligatorio'),
    description: yup.string().min(1, 'Demasiado corto').max(350, 'Demasiado largo').required('Campo obligatorio'),
});

const RoleForm: React.FC<CreateFormProps> = ({ isLoading, submit, data = undefined }) => {

    const navigate = useNavigate();
    const mode = data ? 'Editar' : 'Crear';

    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);

    const [permissionsData] = useFetch(useCallback(async () => {
        const response = await (new PermissionService()).getPermissions();
        return response.getResponseData() as PermissionsApiResponse;
    }, []));

    const formik = useFormik({
        initialValues: {
            roleId: data?.id ?? '',
            name: data?.name ?? '',
            color: data?.color ?? '#607d8b',
            description: data?.description ?? '',
            permissions: data?.permissions.map((permission: RolePermission) => permission.permission.id) ?? []
        },
        validationSchema: schema,
        onSubmit: values => { submit(values, selectedPermissions); },
    });

    useEffect(() => {
        if (data) _handleSelectedPermissions(data.permissions);
    }, [data]);

    const _handleSelectedPermissions = (permissions: RolePermission[]) => {
        let permissionsIds = permissions.map((permission: RolePermission) => permission.permission.id);
        setSelectedPermissions(permissionsIds);
    };

    const _handleSelectAll = (permissions: number[], checked: boolean) => {
        if(checked && selectedPermissions !== null) {
            // if already in selectedPermissions, do nothing
            let permissionsToAdd: number[] = [];
            permissions.forEach((permission: number) => {
                if(!selectedPermissions.includes(permission)) {
                    permissionsToAdd.push(permission);
                }
            });
            setSelectedPermissions([...selectedPermissions, ...permissionsToAdd]);
        }

        if(!checked && selectedPermissions !== null) {
            const selectedPermissionsCopy = selectedPermissions;

            permissions.forEach((permission: number) => {
                const index = selectedPermissionsCopy.indexOf(permission);
                if(index > -1) {
                    selectedPermissionsCopy.splice(index, 1);
                }
            });

            setSelectedPermissions([...selectedPermissionsCopy]);
        }
    };

    const _handleOnSelectPermission = (permission: number, checked: boolean) => {
        if(checked && selectedPermissions !== null) {
            if(!selectedPermissions.includes(permission)) {
                setSelectedPermissions([...selectedPermissions, permission]);
            }
        }

        if(!checked && selectedPermissions !== null) {
            const selectedPermissionsCopy = selectedPermissions;
            const index = selectedPermissionsCopy.indexOf(permission);
            if(index > -1) {
                selectedPermissionsCopy.splice(index, 1);
            }
            setSelectedPermissions([...selectedPermissionsCopy]);
        }
    };

    const getContent = () => {
        return (
            <>
                <div className="@container">
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11 font-medium">

                            <FormGroup
                                title="Información General"
                                description="Datos principales del rol"
                                className='pt-6'
                            >
                                <RequiredInput
                                    id='name'
                                    type="text"
                                    label="Nombre"
                                    className="[&>label>span]:font-medium"
                                    inputClassName="text-sm"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    error={formik.errors.name}
                                />

                                <RequiredInput
                                    id='description'
                                    type="textArea"
                                    label="Descripción"
                                    className="[&>label>span]:font-medium"
                                    inputClassName="text-sm"
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                    error={formik.errors.description}
                                />

                                <div className='flex flex-row flex-wrap gap-3 align-bottom	'>
                                    <Input
                                        id='color'
                                        type="text"
                                        label="Color"
                                        className="[&>label>span]:font-medium"
                                        inputClassName="text-sm flex flex-grow"
                                        onChange={formik.handleChange}
                                        value={formik.values.color}
                                        error={formik.errors.color}
                                    />
                                    <PopoverPicker color={formik.values.color} onChange={(color: string) => formik.setFieldValue('color', color)} />
                                </div>

                            </FormGroup>
   
                            {selectedPermissions !== null && permissionsData &&
                                permissionsData.map((permissionGroup: PermissionGroup, index: number) => {

                                    return (
                                        <FormGroup
                                            key={`index-${permissionGroup.id}`}
                                            title={permissionGroup.label}
                                            description={'Lista de permisos asignados al usuario'}
                                            className='pt-6'
                                            childClassName='col-span-full grid gap-4 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 @4xl:col-span-8 @4xl:gap-5 xl:gap-7'
                                        >
                                            <div key={permissionGroup.id} className={"col-span-full"}>
                                                <PermissionsGrid
                                                    group={permissionGroup}
                                                    onSelectAll={_handleSelectAll}
                                                    onSelectPermission={_handleOnSelectPermission}
                                                    selectedPermissions={selectedPermissions}
                                                />
                                            </div>
                                        </FormGroup>
                                    )
                                })
                            }

                        </div>
                    </form>
                </div>
            </>
        )
    };

    return (
        <Fragment>
            <Page container="fluid">
                {(mode === "Editar" && !data) && <Spinner />}
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Rol'}
                handleCancelBtn={() => navigate(-1)}
                handleSubmitBtn={formik.submitForm} isLoading={isLoading}
            />
        </Fragment>
    )
};

export default RoleForm;