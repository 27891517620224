import classNames from 'classnames';
import React from 'react';
import SelectReact from 'react-select';
import makeAnimated from 'react-select/animated';
import chroma from 'chroma-js';

type SelectProps = {
    id: string;
    label: string;
    error?: string;
    isSearchable?: boolean;
    isMulti?: boolean;
    isClearable?: boolean;
    isDisabled?: boolean;
    options?: any;
    onChange?: any;
    handle?: any;
    value?: any;
    defaultValue?: any;
    placeholder?: string;
    noOption?: any;
    containerClassName?: string;
    className?: string;
    onBlur?: any;
    display?: boolean;
    required?: boolean;
    isLoading?: boolean;
    onInputChange?: any;
    inputValue?: any;
};

const CustomSelect: React.FC<SelectProps> = ({ label, id, isSearchable, isMulti, isClearable, isDisabled, required, options, onChange, handle, value, defaultValue, placeholder, noOption, containerClassName, className, onBlur, display = true, error, isLoading = false, onInputChange, inputValue }) => {
    return (
        <div className={classNames(containerClassName, { 'hidden': !display })}>
            <label className='block'>
                <span className="rizzui-input-label block text-sm mb-1.5 font-medium">{label} {required && <span className="text-red-500">*</span>}</span>
                <SelectReact
                    id={id}
                    name={id}
                    tabSelectsValue
                    components={makeAnimated()}
                    isSearchable={isSearchable}
                    isMulti={isMulti}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isDisabled={isDisabled}
                    options={options}
                    onInputChange={onInputChange}
                    onChange={onChange ? ((selectedOption: any) => { onChange(selectedOption) }) : handle}
                    value={value}
                    inputValue={inputValue}
                    defaultValue={defaultValue}
                    onBlur={onBlur}
                    placeholder={`Elegir ${placeholder || ''}...`}
                    noOptionsMessage={noOption || (() => 'No se ha encontrado ningún resultado')}
                    className={className}
                    styles={{
                        control: (base: any, state: any) => ({
                            ...base,
                            border: '1px solid rgb(229 231 235)',
                            borderRadius: '0.375rem',
                            //boxShadow: state.isFocused ? '0 0 0 2px rgb(0, 172, 216)' : '0 0 0 1px rgba(0,0,0, 0.2) !important',
                            backgroundColor: isDisabled ? '#e9ecef' : 'transparent',
                            '&:hover': {
                                borderColor: 'rgb(0, 172, 216)',
                            },
                            '&:focus': {
                                borderColor: 'rgb(0, 172, 216)',
                                boxShadow: '0 0 0 2px rgb(0, 172, 216)',
                            },
                        }),
                        option: (provided: any, state: any) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'rgb(0, 172, 216)' : 'white',
                            color: state.isFocused ? 'white' : 'black',
                            '&:hover': {
                                backgroundColor: 'rgb(0, 172, 216)',
                                color: 'white',
                                borderColor: '#000000 !important'
                            }
                        }),
                        multiValueLabel: (styles: any, { data }: any) => {
                            const backgroundColor = data.color || '#E6E5E5';
                            let textColor = 'black';
                            if (chroma.valid(backgroundColor)) {
                                textColor = chroma.contrast(backgroundColor, 'white') > 4.5 ? 'white' : 'black';
                            }

                            return {
                                ...styles,
                                backgroundColor,
                                color: textColor,
                            };
                        },
                        multiValueRemove: (styles: any, { data }: any) => {
                            const backgroundColor = data.color || '#E6E5E5';
                            let textColor = 'black';
                            if (chroma.valid(backgroundColor)) {
                                textColor = chroma.contrast(backgroundColor, 'white') > 4.5 ? 'white' : 'black';
                            }

                            return {
                                ...styles,
                                backgroundColor,
                                color: textColor + ' !important',
                                ':hover': {
                                    backgroundColor: chroma.contrast(backgroundColor, 'white') > 4.5 ? chroma(backgroundColor).brighten(0.5).hex() : chroma(backgroundColor).darken(0.5).hex(),
                                    color: textColor,
                                }
                            };
                        },
                    }}
                />
            </label>

            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
    );
};

export default CustomSelect;