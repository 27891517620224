import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import Page from "../../layout/Page/Page";
import Spinner from "../../components/bootstrap/Spinner";
import FormFooter from "../_layout/_footers/form-footer";
import FormGroup from "../../layout/shared/form-group";
import RequiredInput from "../../components/forms/InputHOC";
import { useFormik } from "formik";
import CustomSelect from "../../components/forms/CustomSelect";
import useFetch from "../../hooks/useFetch";
import { toast } from "sonner";
import { Loader } from "../../components/loader/SpinnerLogo";
import useUsers from "../../hooks/api-calls/useUsers";
import useNetworks from "../../hooks/api-calls/useNetworks";
import { DepartmentService } from "../../services/departments/departmentService";
import { Department, DepartmentFieldsModel } from "../../type/entities/department-type";
import { Input } from "rizzui";
import useStates from "../../hooks/api-calls/useStates";
import useProducts from "../../hooks/api-calls/useProducs";

interface CreateFormProps {
    id: string | undefined;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    refetch: () => void;
}

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').required('Campo obligatorio'),
    description: yup.string().max(255, 'Demasiado largo').nullable(),
    commercialNetworkId: yup.string().required('Campo obligatorio'),
    departmentUsers: yup.array().nullable(),
    departmentStatusesToView: yup.array().nullable(),
    departmentStatusesToEdit: yup.array().nullable(),
    departmentProducts: yup.array().nullable(),
});

const DepartmentForm: React.FC<CreateFormProps> = ({ id, isOpen, setIsOpen, refetch }) => {

    const { getNetworksList } = useNetworks();
    const { getUsersList } = useUsers();
    const { getStatesList } = useStates();
    const { getProductsList } = useProducts({ limit: 999999});
    const service = new DepartmentService();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = await service.getDepartmentById(id as string);
        return response.getResponseData() as Department;
    }, [id]));

    const mode = data ? 'Editar' : 'Crear';

    const submit = async (values: any) => {
        setIsLoading(true);

        if (mode === 'Crear') {
            try {
                const response = await service.createDepartment(values);
                const responseData = response.getResponseData();

                if (responseData.success) {
                    toast.success("Departamento creado correctamente");
                    refetch();
                    setIsOpen(false);
                } else {
                    responseData.data.errors.forEach((error: any) => {
                        toast.error(error.message);
                    });
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        } else {
            try {
                const response = await service.editDepartment(values);
                const responseData = response.getResponseData();

                if (responseData.success) {
                    toast.success("Departamento editado correctamente");
                    refetch();
                    setIsOpen(false);
                } else {
                    responseData.data.errors.forEach((error: any) => {
                        toast.error(error.message);
                    });
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const formik = useFormik<DepartmentFieldsModel>({
        initialValues: {
            departmentId: id ?? '',
            name: data?.name ?? '',
            description: data?.description ?? '',
            commercialNetworkId: data?.commercialNetwork?.id ?? '',
            departmentUsers: data?.user?.map((u: any) => u.id) ?? [],
            departmentStatusesToView: data?.departmentsHasStatuses?.filter((s: any) => s.action === 'view').map((s: any) => s.statuses?.id) ?? [],
            departmentStatusesToEdit: data?.departmentsHasStatuses?.filter((s: any) => s.action === 'edit').map((s: any) => s.statuses?.id) ?? [],
            departmentProducts: data?.departmentsHasProduct?.map((p: any) => p.product?.id) ?? [],
        },
        validationSchema: schema,
        onSubmit: values => { submit(values); },
    });

    useEffect(() => {
        if (data) {
            formik.setValues({
                departmentId: data.id,
                name: data.name,
                description: data.description,
                commercialNetworkId: data.commercialNetwork?.id,
                departmentUsers: data.user?.map((u: any) => u.id) ?? [],
                departmentStatusesToView: data.departmentsHasStatuses?.filter((s: any) => s.action === 'view').map((s: any) => s.statuses?.id) ?? [],
                departmentStatusesToEdit: data.departmentsHasStatuses?.filter((s: any) => s.action === 'edit').map((s: any) => s.statuses?.id) ?? [],
                departmentProducts: data.departmentsHasProduct?.map((p: any) => p.product?.id) ?? [],
            });
        }
    }, [data]);

    useEffect(() => {
        if (id === undefined) {
            formik.resetForm();
        }
    }, [id]);

    if (isOpen && id && !data) return <Loader />;

    const getContent = () => {
        return (
            <div className="@container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11 font-medium">
                        <FormGroup
                            title="Información General"
                            description="Datos del departamento"
                        >
                            <RequiredInput
                                id='name'
                                type="text"
                                label="Nombre"
                                className="[&>label>span]:font-medium"
                                inputClassName="text-sm"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={formik.errors.name}
                            />

                            <CustomSelect
                                isSearchable
                                isClearable
                                required
                                id={'commercialNetworkIdId'}
                                label="Red Comercial"
                                value={{ value: formik.values.commercialNetworkId, label: getNetworksList()?.find((g: any) => g.value === formik.values.commercialNetworkId)?.label }}
                                options={getNetworksList()}
                                onChange={(e: any) => { formik.setFieldValue('commercialNetworkId', e ? e.value : null); }}
                                error={formik.errors.commercialNetworkId}
                            />

                            <CustomSelect
                                isMulti
                                isSearchable
                                id={'departmentUsers'}
                                label="Usuarios"
                                value={formik.values.departmentUsers ? getUsersList().filter((u: any) => formik.values.departmentUsers?.includes(u.value)) : []}
                                options={getUsersList()}
                                onChange={(e: any) => {
                                    const selectedIds = e ? e.map((option: any) => option.value) : [];
                                    formik.setFieldValue('departmentUsers', selectedIds);
                                }}
                            />

                            <CustomSelect
                                isMulti
                                isSearchable
                                id={'departmentStatusesToView'}
                                label="Estados que puede visualizar"
                                value={formik.values.departmentStatusesToView ? getStatesList().filter((s: any) => formik.values.departmentStatusesToView?.includes(s.value)) : []}
                                options={getStatesList()}
                                onChange={(e: any) => {
                                    const selectedIds = e ? e.map((option: any) => option.value) : [];
                                    formik.setFieldValue('departmentStatusesToView', selectedIds);
                                }}
                            />

                            <CustomSelect
                                isMulti
                                isSearchable
                                id={'departmentStatusesToEdit'}
                                label="Estados que puede modificar"
                                value={formik.values.departmentStatusesToEdit ? getStatesList().filter((s: any) => formik.values.departmentStatusesToEdit?.includes(s.value)) : []}
                                options={getStatesList()}
                                onChange={(e: any) => {
                                    const selectedIds = e ? e.map((option: any) => option.value) : [];
                                    formik.setFieldValue('departmentStatusesToEdit', selectedIds);
                                }}
                            />

                            <CustomSelect
                                isMulti
                                isSearchable
                                id={'departmentProducts'}
                                label="Productos"
                                value={formik.values.departmentProducts ? getProductsList().filter((p: any) => formik.values.departmentProducts?.includes(p.value)) : []}
                                options={getProductsList()}
                                onChange={(e: any) => {
                                    const selectedIds = e ? e.map((option: any) => option.value) : [];
                                    formik.setFieldValue('departmentProducts', selectedIds);
                                }}
                            />

                            <Input
                                id='description'
                                label="Descripción"
                                className="[&>label>span]:font-medium"
                                inputClassName="text-sm"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                error={formik.errors.description}
                            />
                        </FormGroup>
                    </div>
                </form>
            </div>
        )
    };

    return (
        <>
            {isOpen && (
                <>
                    <Page container="fluid">
                        {(mode === "Editar" && !data) && <Spinner />}
                        {(mode === "Editar" && data) && getContent()}
                        {mode === "Crear" && getContent()}
                    </Page>
                    <FormFooter
                        submitBtnText={mode + ' Departamento'}
                        handleCancelBtn={() => { setIsOpen(false); formik.resetForm(); }}
                        handleSubmitBtn={formik.submitForm} isLoading={isLoading}
                    />
                </>
            )}
        </>
    )
};

export default DepartmentForm;