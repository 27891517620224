import React, { useCallback, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import useFetch from '../../../hooks/useFetch';
import { Button } from 'rizzui';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import Tree from 'react-d3-tree';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { toast } from 'sonner';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { Loader } from '../../../components/loader/SpinnerLogo';
import classNames from 'classnames';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import ButtonPopover from '../../../components/buttons/ButtonPopover';
import moment from 'moment';
import 'moment/locale/es';
import { NetworkService } from '../../../services/networks/networkService';
import NetworksFilters from './NetworksFilters';
import NetworkForm from '../NetworkForm';
import { NetworksApiResponse } from '../../../type/entities/network-type';
import { AuthState } from '../../../redux/authSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

interface TreeNode {
    name: string;
    id: string;
    attributes?: any;
    children?: TreeNode[];
}

interface NetworksListProps {
    openFilters: boolean;
    setOpenFilters: any;
}

const NetworksList: React.FC<NetworksListProps> = ({ openFilters, setOpenFilters }) => {

    const { user }: AuthState = useSelector((state: RootState) => state.auth);
    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, resetFilters } = useFiltersPR();
    const { width, height } = useWindowDimensions();
    const service = new NetworkService();

    const [networks, setNetworks] = useState<TreeNode | undefined>(undefined);
    const [tooltip, setTooltip] = useState<{ visible: boolean; x: number; y: number; nodeData: any | null; }>({ visible: false, x: 0, y: 0, nodeData: null });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [nodeId, setNodeId] = useState<string | undefined>(undefined);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listNetworks(filters);
        return response.getResponseData() as NetworksApiResponse;
    }, [filters]));

    /**
     * Build the tree structure recursively
     */
    function buildTree(data: any, parentId = null, level = '1') {
        // Filter networks that have the current 'parentId' as parent
        const children = data.filter((item: any) => {
            return parentId ? item.parentCommercialNetwork?.id === parentId : !item.parentCommercialNetwork;
        });

        // For each child found, we build its node
        return children.map((child: any, index: number) => {
            console.log('child', child.department.length);
            return {
                name: child.name,
                id: child.id,
                active: child.active,
                createdAt: child.createdAt.date,
                updatedAt: child.updatedAt?.date,
                attributes: {
                    Código: child.code,
                    IP: child.ipAddress || 'N/A',
                   ' Nº Departamentos': (child.department && child.department.length > 0) ? child.department.length : '0',
                    'Nº Usuarios': (child.users && child.users.length > 0) ? child.users.length : '0',
                },
                children: buildTree(data, child.id, `${level}.${index + 1}`),
            };
        });
    };

    /**
     * Generate the networks tree structure
     */
    function generateNetworkTree(data: any) {
        return {
            name: user?.companyName || 'Attiva',
            id: '1',
            children: buildTree(data),
        };
    };

    /**
     * Handle node click event
     */
    const handleNodeClick = (nodeData: any, evt: any) => {
        if (nodeData?.data?.id === '1') return;

        if (userCan('edit_commercial_network', 'commercial_network')) {
            setNodeId(nodeData?.data?.id);
            setIsOpen(true);
        }
    };

    const handleNodeMouseOver = (nodeData: any, evt: any) => {
        if (nodeData?.data?.id === '1') return;

        // Get node position relative to the SVG container
        const { x, y } = evt.target.getBoundingClientRect();

        // Toggle tooltip visibility and update position
        setTooltip({
            visible: true,
            x: x + window.scrollX + 35,
            y: y + window.scrollY,
            nodeData: nodeData as any,
        });
    };

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteNetwork(id)).getResponseData();
        if (response.success) {
            toast.success('Red comercial eliminada correctamente');
            setIsOpen(false);
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Update commercial_network tree when data is fetched
     */
    useEffect(() => {
        if (data?.data.length > 0) {
            setNetworks(generateNetworkTree(data.data));
        } else {
            setNetworks(undefined);
        }
    }, [data, filters]);

    if (loading) return <Loader height={'70'} />;

    return (
        <Page container='fluid'>
            <div className='grid grid-cols-3 gap-6 static'>
                <div id="treeWrapper" className={classNames('col-span-3 relative', { 'md:col-span-2': isOpen, 'hidden': !userCan('list_commercial_network', 'commercial_network') })}>
                    {userCan('create_commercial_network', 'commercial_network') && (
                        <Button
                            onClick={() => { setIsOpen(true); setNodeId(undefined); }}
                            className='absolute top-2'
                            color='primary'
                        >
                            Crear red comercial
                        </Button>
                    )}

                    {nodeId && isOpen && userCan('delete_commercial_network', 'commercial_network') && (
                        <ButtonPopover
                            title='Eliminar red comercial'
                            description='Esto eliminará todo lo que dependa de esta red comercial'
                            onClick={() => handleDelete(nodeId)}
                            btnClassName='absolute top-2 right-0'
                            variant='outline'
                        />
                    )}

                    {(data !== undefined && networks)
                        ? (
                            <Tree
                                data={networks}
                                orientation="vertical"
                                pathFunc={'step'}
                                translate={{ x: width / 3, y: height / 10 }}
                                //dimensions={{ width: width, height: height - 200 }}
                                separation={{ siblings: 2, nonSiblings: 2 }}
                                nodeSize={{ x: 150, y: 150 }}
                                shouldCollapseNeighborNodes
                                collapsible={false}
                                enableLegacyTransitions // Remove for large trees. Works only if collapsible is true
                                hasInteractiveNodes
                                scaleExtent={{ min: 0.4, max: 3 }} // Zoom scale
                                onNodeClick={handleNodeClick}
                                onNodeMouseOver={handleNodeMouseOver}
                                onNodeMouseOut={() => setTooltip({ ...tooltip, visible: false })}
                                rootNodeClassName="node__root"
                                branchNodeClassName="node__branch"
                                leafNodeClassName="node__leaf mt-10"
                            />
                        )
                        : (
                            <div className='flex justify-center items-center h-[calc(100vh-100px)]'>
                                <p className='text-gray-500'>No se han encontrado redes comerciales</p>
                            </div>
                        )
                    }
                </div>

                {tooltip.visible && tooltip.nodeData !== null && (
                    <div
                        onMouseLeave={() => setTooltip({ ...tooltip, visible: false })}
                        style={{
                            position: 'absolute',
                            top: tooltip.y,
                            left: tooltip.x,
                            background: 'white',
                            border: '1px solid #ccc',
                            padding: '10px 15px',
                            borderRadius: '5px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                            minWidth: '120px',
                            maxWidth: '240px',
                            zIndex: 1000
                        }}
                    >
                        <p className='text-sm font-medium text-gray-500'>IP: {tooltip.nodeData?.data?.ipAddress ? tooltip.nodeData.data.ipAddress : 'N/A'}</p>
                        <p className='text-sm font-medium text-gray-500'>Creación: {moment(tooltip.nodeData?.data?.createdAt).format('L')}</p>
                        <p className='text-sm font-medium text-gray-500'>Actualización: {tooltip.nodeData?.data?.updatedAt ? moment(tooltip.nodeData.data.updatedAt).format('L') : 'N/A'}</p>
                    </div>
                )}

                <div className='col-span-3 md:col-span-1'>
                    <NetworkForm id={nodeId} isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} />
                </div>
            </div>

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Empresas'}>
                <div className="grid grid-cols-1 gap-6">
                    <NetworksFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </Page >
    );
};

export default NetworksList;