import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { DepartmentFieldsModel } from '../../type/entities/department-type';

const ENDPOINT = '/departments';

export class DepartmentService extends RestServiceConnection {

    listDepartments = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getDepartmentById = async (departmentId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { departmentId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleDepartmentState = async (departmentId: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { departmentId, active: status },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createDepartment = async (department: DepartmentFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: department,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editDepartment = async (department: DepartmentFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: department,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteDepartment = async (departmentsId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { departmentsId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiDepartments = async (departmentsId: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { departmentsId }
        }, true);
        return this;
    }

    copyConfiguration = async (departmentId: string, departmentsIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/copy-configuration',
            data: { departmentId, departmentsIds },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

}