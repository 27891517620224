import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import Page from "../../layout/Page/Page";
import Spinner from "../../components/bootstrap/Spinner";
import FormFooter from "../_layout/_footers/form-footer";
import FormGroup from "../../layout/shared/form-group";
import RequiredInput from "../../components/forms/InputHOC";
import { useFormik } from "formik";
import CustomSelect from "../../components/forms/CustomSelect";
import { Select } from "rizzui";
import { validateCifNif } from "../../utils/cifNifValidator";
import IBAN from 'iban';
import { Corporation } from "../../type/entities/corporation-type";
import { CorporationService } from "../../services/corporation/corporationService";
import useFetch from "../../hooks/useFetch";
import { toast } from "sonner";
import useCorporations from "../../hooks/api-calls/useCorporations";
import { Loader } from "../../components/loader/SpinnerLogo";

interface CreateFormProps {
    id: string | undefined;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    refetch: () => void;
}

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').required('Campo obligatorio'),
    corporationFunction: yup.string().required('Campo obligatorio'),
    cif: yup.string().test('valid-cif-nif', 'El formato de CIF/NIF no es válido', (value) => {
        if (typeof value === 'string') {
            return validateCifNif(value);
        } else {
            return true;
        }
    }).required('Campo obligatorio'),
    iban: yup.string().test('iban', 'El formato de IBAN no es válido', (value: any) => IBAN.isValid(value)),
    registeredOffice: yup.string().required('Campo obligatorio'),
    parentCorporationId: yup.string().nullable(),
});

export const corporationFunctionOptions = [
    { value: 'delivering', label: 'Impartidora' },
    { value: 'organizing', label: 'Organizadora' },
];

const CorporationForm: React.FC<CreateFormProps> = ({ id, isOpen, setIsOpen, refetch }) => {

    const { getCorporationsList } = useCorporations();
    const service = new CorporationService();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = await service.getCorporationById(id as string);
        return response.getResponseData() as Corporation;
    }, [id]));

    const mode = data ? 'Editar' : 'Crear';

    const submit = async (values: any) => {
        setIsLoading(true);

        if (mode === 'Crear') {
            try {
                const response = await service.createCorporation(values);
                const responseData = response.getResponseData();

                if (responseData.success) {
                    toast.success("Empresa creada correctamente");
                    refetch();
                    setIsOpen(false);
                } else {
                    responseData.data.errors.forEach((error: any) => {
                        toast.error(error.message);
                    });
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        } else {
            try {
                const response = await service.editCorporation(values);
                const responseData = response.getResponseData();

                if (responseData.success) {
                    toast.success("Empresa editada correctamente");
                    refetch();
                    setIsOpen(false);
                } else {
                    responseData.data.errors.forEach((error: any) => {
                        toast.error(error.message);
                    });
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            corporationId: id ?? '',
            name: data?.name ?? '',
            corporationFunction: data?.corporationFunction ?? '',
            cif: data?.cif ?? '',
            iban: data?.iban ?? '',
            registeredOffice: data?.registeredOffice ?? '',
            parentCorporationId: data?.parentCorporation?.id ?? null,
        },
        validationSchema: schema,
        onSubmit: values => { submit(values); },
    });

    useEffect(() => {
        if (data) {
            formik.setValues({
                corporationId: data.id,
                name: data.name,
                corporationFunction: data.corporationFunction,
                cif: data.cif,
                iban: data.iban,
                registeredOffice: data.registeredOffice,
                parentCorporationId: data.parentCorporation?.id ?? null,
            });
        }
    }, [data]);

    useEffect(() => {
        if (id === undefined) {
            formik.resetForm();
        }
    }, [id]);

    if (isOpen && id && !data) return <Loader />;

    const getContent = () => {
        return (
            <div className="@container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11 font-medium">
                        <FormGroup
                            title="Información General"
                            description="Datos de la empresa"
                        >
                            <RequiredInput
                                id='name'
                                type="text"
                                label="Nombre"
                                className="[&>label>span]:font-medium"
                                inputClassName="text-sm"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={formik.errors.name}
                            />

                            <RequiredInput
                                id='cif'
                                label="CIF/NIF"
                                className="[&>label>span]:font-medium"
                                inputClassName="text-sm"
                                onChange={formik.handleChange}
                                value={formik.values.cif}
                                error={formik.errors.cif}
                            />

                            <Select
                                id='corporationFunction'
                                label={<span>Función <span className="text-red-500">*</span> </span>}
                                className="[&>label>span]:font-medium"
                                value={corporationFunctionOptions.find((g: any) => g.value === formik.values.corporationFunction)}
                                options={corporationFunctionOptions}
                                onChange={(e: any) => { formik.setFieldValue('corporationFunction', e?.value); }}
                                onError={formik.errors.corporationFunction}
                                placeholder="Selecciona una opción"
                            />

                            <CustomSelect
                                isSearchable
                                id={'parentCorporationId'}
                                label="Empresa Padre"
                                value={{ value: formik.values.parentCorporationId, label: getCorporationsList()?.find((g: any) => g.value === formik.values.parentCorporationId)?.label }}
                                options={getCorporationsList()}
                                onChange={(e: any) => { formik.setFieldValue('parentCorporationId', e?.value); }}
                                placeholder="Selecciona una opción"
                            />

                            <RequiredInput
                                id='iban'
                                label="IBAN"
                                className="[&>label>span]:font-medium"
                                inputClassName="text-sm"
                                onChange={formik.handleChange}
                                value={formik.values.iban}
                                error={formik.errors.iban}
                            />

                            <RequiredInput
                                id='registeredOffice'
                                label="Domilicio Social"
                                className="[&>label>span]:font-medium"
                                inputClassName="text-sm"
                                onChange={formik.handleChange}
                                value={formik.values.registeredOffice}
                                error={formik.errors.registeredOffice}
                            />
                        </FormGroup>
                    </div>
                </form>
            </div>
        )
    };

    return (
        <>
            {isOpen && (
                <>
                    <Page container="fluid">
                        {(mode === "Editar" && !data) && <Spinner />}
                        {(mode === "Editar" && data) && getContent()}
                        {mode === "Crear" && getContent()}
                    </Page>
                    <FormFooter
                        submitBtnText={mode + ' Empresa'}
                        handleCancelBtn={() => { setIsOpen(false); formik.resetForm(); }}
                        handleSubmitBtn={formik.submitForm} isLoading={isLoading}
                    />
                </>
            )}
        </>
    )
};

export default CorporationForm;