import { Fragment, useState } from "react";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import CorporationsList from "./CorporationsList";
import { Button } from "rizzui";
import { PiFunnel } from "react-icons/pi";

const CorporationsListWrapper = () => {

    const breadCrumb = [
        { name: 'Administración' },
        { name: 'Empresas', href: menuRoutes.corporations.path },
    ];

    const [openFilters, setOpenFilters] = useState<boolean>(false);

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de empresas" breadcrumb={breadCrumb}>
                    {/* <Button
                        variant="outline"
                        onClick={() => { setOpenFilters(true) }}
                    >
                        <PiFunnel className="me-1.5 h-[18px] w-[18px]" strokeWidth={1.7} />
                        Filtros
                    </Button> */}
                </PageHeader>
                <CorporationsList openFilters={openFilters} setOpenFilters={setOpenFilters} />
            </FiltersProvider>
        </Fragment>
    );
}

export default CorporationsListWrapper;