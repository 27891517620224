import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import StatesListLayout from './components/StatesListLayout';
import FilteredStatesTable from './components/FilteredStatesTable';
import StatesFilters from './StatesFilters';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';

interface StatesListProps {
}

const StatesList: React.FC<StatesListProps> = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { selectedView } = useSelector((state: RootState) => state.states);
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();

    const [openFilters, setOpenFilters] = useState<boolean>(false);


    /**
     * Redirect to the selected view after the page is loaded
     */
    useEffect(() => {
        if (location.pathname === '/states') {
            setTimeout(() => {
                navigate(`/states${selectedView}`);
            }, 100);
        }
    }, []);

    return (
        <Page container='fluid'>
            <StatesListLayout>
                <FilteredStatesTable
                    filters={filters} updateFilters={updateFilters} updateFilterOrder={updateFilterOrder} updatePage={updatePage} updatePageSize={updatePageSize}
                    openFilters={openFilters} setOpenFilters={setOpenFilters}
                />
            </StatesListLayout>

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Estados'}>
                <div className="grid grid-cols-1 gap-6">
                    <StatesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </Page>
    );
};

export default StatesList;