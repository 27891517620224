import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import tableReducer from './tableSlice';
import userProfileReducer from './userProfileSlice';
import tutorProfileReducer from './tutorProfileSlice';
import { loadState, saveState } from './browser-storage';
import { debounce } from "../helpers/helpers";
import statesSlice from './statesSlice';

const store = configureStore({
  reducer: {
    //@ts-ignore
    auth: authReducer,
    table: tableReducer,
    userProfile: userProfileReducer,
    tutorProfile: tutorProfileReducer,
    states: statesSlice,
  },
  preloadedState: loadState(),
});

// here we subscribe to the store changes
store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  debounce(() => {
    saveState(store.getState());
  }, 800));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;