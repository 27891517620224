import { Fragment, useContext } from "react";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { Button } from "rizzui";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useNavigate } from "react-router";
import { menuRoutes } from "../../../router/menu";
import cn from "../../../utils/classNames";
import ProductsList from "./ProductsList";

const ProductsListWrapper = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    const breadCrumb = [
        { name: 'Administración' },
        { name: 'Control de acceso' },
        { name: 'Productos', href: menuRoutes.products.path },
    ];
    
    const defaultFilters = {
        active: true,
    };

    return (
        <Fragment>
            <FiltersProvider defaultFilterFilters={defaultFilters}>
                <PageHeader title="Listado de productos" breadcrumb={breadCrumb}>
                    {
                        userCan('create_products', 'products') &&
                        (
                            <Button
                                color="primary"
                                onClick={() => { navigate(menuRoutes.products.create) }}
                                className={cn(['mt-4 md:mt-0'])}
                            >
                                Crear producto
                            </Button>
                        )
                    }

                </PageHeader>
                <ProductsList />
            </FiltersProvider>
        </Fragment>
    );
}

export default ProductsListWrapper;