import { PiFolder, PiGridFourDuotone, PiNewspaperClippingDuotone } from "react-icons/pi";
import { HeaderMenuItem } from "../type/menu-type";

export const menuRoutes = {
    dashboard: {
        path: "/dashboard",
    },
    organisations: {
        path: "/organisations",
        create: "create",
        edit: ":id/edit",
    },
    roles: {
        path: "/roles",
        create: "create",
        edit: ":id/edit",
    },
    users: {
        path: "/users",
        create: "create",
        edit: ":id/edit/info",
        edit_permissions: ":id/edit/permissions",
        profile: {
            path: ":id/profile",
            info: "/info",
            stats: "/stats",
            documents: "/documents",
            permissions: "/permissions",
            contract_history: "/contract-history",
            activity: "/activity",
        },
    },
    products: {
        path: "/products",
        create: "create",
        edit: ":id/edit",
    },
    states: {
        path: "/states",
        create: ":entity/create",
        edit: ":id/edit",
        list: {
            contract: "/contract_states",
            contract_product: "/contract_product_states",
            contract_invoicing: "/contract_invoicing_states",
            contract_incidence: "/contract_incidence_states",
            expiration: "/expiration_states",
            invoice: "/invoice_states",
            incidence: "/incidence_states",
            renovation: "/renovation_states",
        }
    },
    corporations: {
        path: "/corporations",
        create: "create",
        edit: ":id/edit",
    },
    tutors: {
        path: "/tutors",
        create: "create",
        edit: ":id/edit",
        profile: {
            path: ":id/profile",
            info: "/info",
            stats: "/stats",
            documents: "/documents",
            invoices: "/invoices",
            contracts: "/contracts",
        },
    },
    networks: {
        path: "/networks",
        create: "create",
        edit: ":id/edit",
    },
    departments: {
        path: "/departments",
        create: "create",
        edit: ":id/edit",
    },
};

export const attivaMenuItems: HeaderMenuItem = {
    dashboardMenu: {
        id: "dashboard",
        name: "Inicio",
        path: "/dashboard",
        type: "enhance",
        permissions_required: [{
            group: "dashboard",
            action: "get_dashboard_information",
        }],
    },
    administrationMenu: {
        id: "administration",
        name: "Administración",
        path: "/admin",
        react_icon: PiFolder,
        type: "enhance",
        dropdownItems: [
            {
                id: "access-and-credentials",
                name: "Control de acceso",
                path: "",
                icon: "UserLockIcon",
                subMenuItems: [
                    {
                        name: "Usuarios",
                        href: menuRoutes.users.path,
                        icon: "FolderIcon",
                        permissions_required: [
                            {
                                group: "user",
                                action: "list_user",
                            }
                        ],
                    },
                    {
                        name: "Tutores",
                        href: menuRoutes.tutors.path,
                        icon: "FolderIcon",
                        permissions_required: [
                            {
                                group: "tutors",
                                action: "admin_tutors",
                            }
                        ],
                    },
                    {
                        name: "Roles",
                        href: menuRoutes.roles.path,
                        icon: "RolesIcon",
                        permissions_required: [
                            {
                                group: "roles",
                                action: "list_roles",
                            },
                        ],
                    },
                ]
            },
            {
                id: "states",
                name: "Gestión de estados",
                icon: "DocumentIcon",
                subMenuItems: [
                    {
                        name: "Estados",
                        href: menuRoutes.states.path,
                        icon: "FolderIcon",
                        permissions_required: [
                            {
                                group: "statuses",
                                action: "admin_statuses",
                            }
                        ],
                    },
                ]
            },
            {
                id: "products",
                name: "Gestión productos",
                icon: "PackageOpenIcon",
                subMenuItems: [
                    {
                        name: "Productos",
                        href: menuRoutes.products.path,
                        icon: "FolderIcon",
                        permissions_required: [
                            {
                                group: "products",
                                action: "list_products",
                            }
                        ],
                    },
                ]
            },
            {
                id: "corporations",
                name: "Gestión empresas",
                path: "",
                icon: "ApartmentIcon",
                subMenuItems: [
                    {
                        name: "Empresas",
                        href: menuRoutes.corporations.path,
                        icon: "FolderIcon",
                        permissions_required: [
                            {
                                group: "corporation",
                                action: "admin_corporation",
                            }
                        ],
                    },
                ]
            },
            {
                id: "networks",
                name: "Gestión de redes",
                path: "",
                icon: "NetworksIcon",
                subMenuItems: [
                    {
                        name: "Redes comerciales",
                        href: menuRoutes.networks.path,
                        icon: "FolderIcon",
                        permissions_required: [
                            {
                                group: "commercial_network",
                                action: "admin_commercial_network",
                            }
                        ],
                    },
                    {
                        name: "Departamentos",
                        href: menuRoutes.departments.path,
                        icon: "DepartmentIcon",
                        permissions_required: [
                            {
                                group: "departments",
                                action: "admin_departments",
                            }
                        ],
                    },
                ]
            },
        ]
    },
    superAdminMenu: {
        id: "title-super-admin",
        name: "Panel Administración",
        icon: "GreenLeafIcon",
        react_icon: PiFolder,
        type: "enhance",
        columns: "1",
        permissions_required: [
            {
                group: "companies",
                action: "admin_company",
                isSuperAdmin: true,
            },
        ],
        dropdownItems: [
            {
                id: "organisations",
                name: "Organizaciones",
                path: menuRoutes.organisations.path,
                icon: "ApartmentIcon",
                react_icon: PiGridFourDuotone,
                permissions_required: [
                    {
                        group: "companies",
                        action: "admin_company",
                        isSuperAdmin: true,
                    },
                ],
                subMenuItems: [
                    {
                        name: "Lista Organizaciones",
                        href: menuRoutes.organisations.path,
                        permissions_required: [
                            {
                                group: "companies",
                                action: "list_company",
                            }
                        ],
                    },
                    {
                        name: "Crear Organización",
                        href: menuRoutes.organisations.path + '/' + menuRoutes.organisations.create,
                        permissions_required: [
                            {
                                group: "companies",
                                action: "create_company",
                            }
                        ],
                    },
                ]
            },
            {
                id: "roles",
                name: "Roles",
                path: menuRoutes.roles.path,
                icon: "RolesIcon",
                react_icon: PiNewspaperClippingDuotone,
                permissions_required: [
                    {
                        group: "roles",
                        action: "admin_roles",
                        isSuperAdmin: true,
                    },
                ],
                subMenuItems: [
                    {
                        name: "Lista Roles",
                        href: menuRoutes.roles.path,
                        permissions_required: [
                            {
                                group: "roles",
                                action: "list_roles",
                            }
                        ],
                    },
                    {
                        name: "Crear Rol",
                        href: menuRoutes.roles.path + '/' + menuRoutes.roles.create,
                        permissions_required: [
                            {
                                group: "roles",
                                action: "create_roles",
                            }
                        ],
                    },
                ]
            },
        ],
    },
};