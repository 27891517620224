import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { FilterOptions } from '../../hooks/useFilters';

const ENDPOINT = '/tutor';

export class TutorService extends RestServiceConnection {

    listTutors = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getTutorById = async (tutorId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { tutorId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleTutorStatus = async (tutorId: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { tutorId, active: status },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    addTutorDocument = async (data: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/add-document',
            data: data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    createTutor = async (tutor: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: tutor,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editTutor = async (tutor: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: tutor,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteTutor = async (tutorId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { tutorId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiTutors = async (tutorsIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { tutorsIds }
        }, true);
        return this;
    }

    changePassword = async (tutorId: string, password: string, passwordConfirm: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-password',
            data: { tutorId, password, passwordConfirm }
        }, true);
        return this;
    }

    listTutorDocuments = async (filters: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-documents',
            data: filters,
        }, true);
        return this;
    }

    getTutorDocuments = async (tutorId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-documents',
            data: { tutorId },
        }, true);
        return this;
    }

    listChangelogs = async (tutorId: string, filters?: any) => {        
        filters.filter_filters.tutor = tutorId;
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-tutor-changes-log',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    listRolesByTutor = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-tutor-roles',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

}