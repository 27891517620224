import { useFormik } from "formik";
import { FC, Fragment, useState } from "react";
import Spinner from "../../components/bootstrap/Spinner";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../layout/Page/Page";
import { FileInput, Input } from "rizzui";
import FormGroup from "../../layout/shared/form-group";
import { menuRoutes } from "../../router/menu";
import { validateCifNif } from "../../utils/cifNifValidator";
import FormFooter from "../_layout/_footers/form-footer";
import { Organisation } from "../../type/entities/organisation-type";
import classNames from "classnames";
import DeletePopover from "../../components/buttons/DeletePopover";
import AsyncImg from "../../components/extras/AsyncImg";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import { OrganisationService } from "../../services/organisation/organisationService";
import { toast } from "sonner";


interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    data?: Organisation | undefined;
    logo?: any;
}

const schema = yup.object({
    cif: yup.string().test('valid-cif-nif', 'El CIF/DNI no es válido', (value) => {
        if (typeof value === 'string') {
            return validateCifNif(value);
        } else {
            return true;
        }
    }).required('Campo obligatorio'),
    name: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').required('Campo obligatorio'),
    address: yup.string().required('Campo obligatorio'),
});

//@ts-ignore
const OrganisationForm: FC<CreateFormProps> = ({ isLoading, submit, data, logo }) => {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const mode = data ? 'Editar' : 'Crear';
    const service = new OrganisationService();

    const [selectedImage, setSelectedImage] = useState<any>(null);

    const handleImageUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        try {
            const response = await service.editOrganisationImg(id, file);

            const responseData = response.getResponseData();

            if (responseData.success) {
                setTimeout(() => {
                    toast.success('Logo actualizado');
                }, 100);
            } else {
                toast.error(responseData.message);
                setSelectedImage(null);
            }
        } catch (error: any) {
            toast.error("Formato de logo incorrecto");
            setSelectedImage(null);
        }
    };

    const deleteImage = async () => {
        try {
            const response = await service.deleteOrganisationImg(id);

            const responseData = response.getResponseData();

            if (responseData.success) {
                setSelectedImage(null);
                window.location.reload();
            }
        } catch (error: any) {
            toast.error("Error al eliminar la imagen");
        }
    };

    const formik = useFormik({
        initialValues: {
            companyId: data?.id ?? '',
            cif: data?.cif ?? '',
            name: data?.name ?? '',
            address: data?.address ?? '',
            logo: data?.logo?.id ?? undefined,
        },
        validationSchema: schema,
        onSubmit: values => submit(values),
    });

    const getContent = () => {
        return (
            <>
                <div className="@container">
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11 font-medium">
                            <FormGroup
                                title="Logo de la organización"
                                description="Se mostrará como logo principal de la organización"
                                className={classNames({ 'hidden': mode === 'Crear', 'mt-4': true })}
                            >
                                <div className='flex justify-start items-center'>
                                    <FileInput onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)} autoComplete='photo' placeholder={"Cambiar logo"} className='me-3' />
                                    <DeletePopover
                                        title={`Eliminar logo`}
                                        description={`¿Estás seguro de que deseas eliminar el logo?`}
                                        onDelete={deleteImage}
                                        size={32}
                                        className={classNames({ 'hidden': !formik.values.logo })}
                                    />
                                </div>
                                {selectedImage
                                    ? <img src={selectedImage} alt="selected" className='mx-auto d-block img-fluid rounded' />
                                    : formik.values.logo
                                        ? <AsyncImg id={formik.values.logo} isBackground className="mx-auto d-block img-fluid rounded" />
                                        : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid rounded' />
                                }
                            </FormGroup>

                            <FormGroup
                                title="Información General"
                                description="Datos principales de una organización"
                            >
                                <Input
                                    id='cif'
                                    type="text"
                                    label="CIF/NIF"
                                    className="[&>label>span]:font-medium"
                                    inputClassName="text-sm"
                                    onChange={formik.handleChange}
                                    value={formik.values.cif}
                                    error={formik.errors.cif}
                                />

                                <Input
                                    id='name'
                                    type="text"
                                    label="Nombre"
                                    className="[&>label>span]:font-medium"
                                    inputClassName="text-sm"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    error={formik.errors.name}
                                />

                                <Input
                                    id='address'
                                    type="text"
                                    label="Dirección"
                                    className="[&>label>span]:font-medium"
                                    inputClassName="text-sm"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    error={formik.errors.address}
                                />
                            </FormGroup>
                        </div>
                    </form>
                </div>
            </>
        )
    }

    return (
        <Fragment>
            <Page container="fluid">
                {(mode === "Editar" && !data) && <Spinner />}
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Organización'}
                handleCancelBtn={() => navigate(-1)}
                handleSubmitBtn={formik.submitForm} isLoading={isLoading}
            />
        </Fragment>
    )
}

export default OrganisationForm;