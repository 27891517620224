import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { StateService } from '../../../../services/state/stateService';
import useFetch from '../../../../hooks/useFetch';
import { StatesApiResponse } from '../../../../type/entities/state-type';
import { Link, useLocation } from 'react-router-dom';
import CustomTable from '../../../../components/table/CustomTable';
import { ActionIcon, Switch, Tooltip } from 'rizzui';
import moment from 'moment';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { menuRoutes } from '../../../../router/menu';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import DeletePopover from '../../../../components/buttons/DeletePopover';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import { IoStar } from 'react-icons/io5';
import { IoMdStarOutline } from 'react-icons/io';
import { FaThumbsUp, FaThumbsDown, FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import cn from '../../../../utils/classNames';

interface FilteredStatesTableProps {
    filters: any;
    updateFilters: any;
    updateFilterOrder: any;
    updatePage: any;
    updatePageSize: any;
    openFilters: boolean;
    setOpenFilters: any;
}

const columnOrder = [
    'id',
    'statusOrder',
    'name',
    'description',
    'color',
    'initialStatus',
    'satisfactoryStatus',
    'unsatisfactoryStatus',
    'active',
    'createdAt',
    'updatedAt',
];

const FilteredStatesTable: React.FC<FilteredStatesTableProps> = ({ filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, openFilters, setOpenFilters }) => {

    const { user } = useSelector((state: RootState) => state.auth);
    const location = useLocation();
    const { handleErrors } = useHandleErrors();
    const service = new StateService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listStates(filters);
        return response.getResponseData() as StatesApiResponse;
    }, [filters]));

    const updateStateOrder = async (id: string, order: 'up' | 'down') => {
        const response = await (await service.updateStateOrder(id, order)).getResponseData();

        if (response.success) {
            toast.success('Orden actualizado');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const toggleState = async (id: string, state: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleStateStatus(id, state)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(state ? "Se ha desactivado el estado" : "Se ha activado el estado");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteState(id)).getResponseData();

        if (response.success) {
            toast.success('Estado eliminado');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.deleteMultiStates(ids)).getResponseData();

        if (response.success) {
            toast.success('Estados eliminados');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const toggleMainState = async (id: string) => {
        const response = await (await service.toggleInitialState(id)).getResponseData();

        if (response.success) {
            toast.success('Estado establecido como inicial');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    /**
     * Update filters when the location changes, getting the entity from the URL
     */
    useEffect(() => {
        const entity = location.pathname.replace('/states/', '').replace('_states', '');
        updateFilters({ entity: entity });
    }, [location.pathname]);

    return (
        <>
            {data !== undefined && data && data.data.length > 0 && (
                <CustomTable
                    id={'states-table'}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    columnsNotShown={['entity', 'productType', 'updatedAt']}
                    overrideColumns={[
                        {
                            key: 'statusOrder',
                            render: (row: any) => {
                                return (
                                    <div className="flex justify-center gap-4" key={row.row.original.id}>
                                        <Tooltip
                                            size="sm"
                                            content={'Subir'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className={cn(['hover:!border-gray-900 hover:text-gray-700'], { 'hidden': row.row.index === 0 })}
                                                onClick={() => updateStateOrder(row.row.original.id, 'down')}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                                                </svg>
                                            </ActionIcon>
                                        </Tooltip>

                                        <span className="text-gray-700">{row.row.original.statusOrder}</span>

                                        <Tooltip
                                            size="sm"
                                            content={'Bajar'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className={cn(['hover:!border-gray-900 hover:text-gray-700'], { 'hidden': row.row.index === data.data.length - 1 })}
                                                onClick={() => updateStateOrder(row.row.original.id, 'up')}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                                                </svg>
                                            </ActionIcon>
                                        </Tooltip>
                                    </div>
                                );
                            },
                        },
                        {
                            key: 'color',
                            render: (row: any) => {
                                if (!row.row.original?.color) return null;
                                return (
                                    <div
                                        key={row.row.original.id + row.row.original?.color}
                                        style={{
                                            backgroundColor: row.row.original?.color,
                                            border: "1px solid rgba(0, 0, 0, 0.1)",
                                            width: "35px",
                                            height: "35px",
                                        }}
                                        className={'rounded-full mr-3 p-3'}
                                    />
                                );
                            },
                        },
                        {
                            key: 'initialStatus',
                            render: (row: any) => {
                                return (
                                    <div className="flex justify-center" key={row.row.original.id}>
                                        <div className="relative group">
                                            {/* <div className="absolute hidden group-hover:block bg-slate-800 text-white text-xs rounded p-2">
                                                {row.row.original.initialStatus ? `Es el estado inicial` : "Establecer este estado como inicial"}
                                            </div> */}
                                            {row.row.original.initialStatus
                                                ? <IoStar
                                                    size={23} color='#444445'
                                                    onClick={() => {
                                                        if (row.row.original.initialStatus) return;
                                                        toggleMainState(row.row.original.id);
                                                    }} />
                                                : <IoMdStarOutline
                                                    size={25} color='#b7b8b9' className='cursor-pointer'
                                                    onClick={() => {
                                                        if (row.row.original.initialStatus) return;
                                                        toggleMainState(row.row.original.id);
                                                    }} />
                                            }
                                        </div>
                                    </div>
                                );
                            }
                        },
                        {
                            key: 'satisfactoryStatus',
                            render: (row: any) => {
                                return (
                                    <div className="flex justify-center" key={row.row.original.id}>
                                        <div className="flex justify-center" key={row.row.original.id}>
                                            <div className="relative group">
                                                {/* <div className="absolute hidden group-hover:block bg-slate-800 text-white text-xs rounded p-2">
                                                    {row.row.original.satisfactoryStatus ? `Es el estado satisfactorio` : "Establecer este estado como satisfactorio"}
                                                </div> */}
                                                {row.row.original.satisfactoryStatus
                                                    ? <FaThumbsUp
                                                        size={18} color='#444445'
                                                        onClick={() => {
                                                            if (row.row.original.satisfactoryStatus) return;
                                                            toggleMainState(row.row.original.id);
                                                        }} />
                                                    : <FaRegThumbsUp
                                                        size={18} color='#b7b8b9' className='cursor-pointer'
                                                        onClick={() => {
                                                            if (row.row.original.satisfactoryStatus) return;
                                                            toggleMainState(row.row.original.id);
                                                        }} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        },
                        {
                            key: 'unsatisfactoryStatus',
                            render: (row: any) => {
                                return (
                                    <div className="flex justify-center" key={row.row.original.id}>
                                        <div className="flex justify-center" key={row.row.original.id}>
                                            <div className="relative group">
                                                {/* <div className="absolute hidden group-hover:block bg-slate-800 text-white text-xs rounded p-2">
                                                    {row.row.original.satisfactoryStatus ? `Es el estado insatisfactorio` : "Establecer este estado como insatisfactorio"}
                                                </div> */}
                                                {row.row.original.unsatisfactoryStatus
                                                    ? <FaThumbsDown
                                                        size={18} color='#444445'
                                                        onClick={() => {
                                                            if (row.row.original.unsatisfactoryStatus) return;
                                                            toggleMainState(row.row.original.id);
                                                        }} />
                                                    : <FaRegThumbsDown
                                                        size={18} color='#b7b8b9' className='cursor-pointer'
                                                        onClick={() => {
                                                            if (row.row.original.unsatisfactoryStatus) return;
                                                            toggleMainState(row.row.original.id);
                                                        }} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        },
                        {
                            key: 'createdAt',
                            render: (row: any) => {
                                return moment(row.row.original.createdAt?.date).format('DD/MM/YYYY')
                            }
                        },
                        {
                            key: 'active',
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={row.row.original.active ? 'Desactivar estado' : 'Activar estado'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Switch
                                            id={row.row.original.id}
                                            checked={row.row.original.active}
                                            onChange={() => toggleState(row.row.original.id, row.row.original.active)}
                                        />
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    actions={[
                        {
                            label: 'Edit',
                            permissions: { group: 'statuses', permission: 'edit_statuses' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar estado'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.states.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'statuses', permission: 'delete_statuses' },
                            render: (row: any) => {
                                if (row.id === user?.id) return null;
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar estado'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar estado`}
                                                description={`¿Estás seguro de que deseas eliminar el estado ${row.name}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as StatesApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                />
            )}
        </>
    );
};

export default FilteredStatesTable;