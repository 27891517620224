import { useState } from 'react';
import cn from '../../../utils/classNames';
import { useMedia } from 'react-use';
import { ActionIcon, Button, Checkbox, Popover, Title } from 'rizzui';
import { type Table as ReactTableType } from '@tanstack/react-table';
import { PiTrash, PiFunnel, PiTextColumns, } from 'react-icons/pi';
import CustomSearchInput from '../../forms/CustomSearchInput';
import { FilterOptions } from '../../../hooks/useFilters';
import { useDispatch } from 'react-redux';
import { setColumnVisibility } from '../../../redux/tableSlice';
import ButtonPopover from '../../buttons/ButtonPopover';

interface TableToolbarProps<T extends Record<string, any>> {
    tableId: string;
    table: ReactTableType<T>;
    filters: FilterOptions;
    updateFilters: Function;
    search?: boolean;
    filter?: boolean;
    toggleFilters?: Function;
}

export default function TableToolbar<TData extends Record<string, any>>(
    {
        tableId,
        table,
        filters,
        updateFilters,
        search = true,
        filter = true,
        toggleFilters,
    }: TableToolbarProps<TData>) {

    const dispatch = useDispatch();
    const isMediumScreen = useMedia('(max-width: 1860px)', false);
    const isMultipleSelected = table.getSelectedRowModel().rows.length > 0;
    const { options: { meta } } = table;

    const [openDrawer, setOpenDrawer] = useState(false);

    /**
     * Maneja la visibilidad de las columnas en redux
     */
    const handleVisibilityChange = (columnId: string, isVisible: boolean) => {
        dispatch(setColumnVisibility({ tableId, columnId, isVisible: !isVisible }));
    };

    return (
        <div className="flex items-center justify-between mb-6">
            {(search || filter) && (
                <div className="flex flex-wrap items-center gap-4">
                    {search && <CustomSearchInput onSearch={(e) => updateFilters({ search_text: e })}
                        defaultValue={filters.filter_filters?.search_text || ''} />}
                </div>
            )}

            <div className="flex items-center gap-4">
                {isMultipleSelected ? (
                    <ButtonPopover
                        title='Eliminar'
                        description='¿Estás seguro de eliminar los elementos seleccionados?'
                        //@ts-ignore
                        onClick={() => meta?.handleMultipleDelete && meta.handleMultipleDelete(table.getSelectedRowModel().rows.map((r) => r.original.id))}
                        variant='outline'
                    />
                ) : null}

                {table && (
                    <Popover shadow="sm" placement="bottom-end">
                        <Popover.Trigger>
                            <ActionIcon
                                variant="outline"
                                title={'Toggle Columns'}
                                className="h-auto w-auto p-1"
                            >
                                <PiTextColumns strokeWidth={3} className="size-6" />
                            </ActionIcon>
                        </Popover.Trigger>
                        <Popover.Content className="z-0">
                            <div className="p-2 text-left rtl:text-right">
                                <Title as="h6" className="mb-6 px-0.5 text-sm font-semibold">
                                    Mostrar/Ocultar columnas
                                </Title>
                                <div className="grid grid-cols-2 gap-6">
                                    {table.getAllLeafColumns().map((column) => {
                                        const columnId = column.id;
                                        return (
                                            typeof column.columnDef.header === 'string' &&
                                            column.columnDef.header.length > 0 && (
                                                <Checkbox
                                                    key={columnId}
                                                    label={<>{column.columnDef.header}</>}
                                                    checked={column.getIsVisible()}
                                                    onChange={() => {
                                                        column.toggleVisibility();
                                                        handleVisibilityChange(columnId, column.getIsVisible());
                                                    }}
                                                />
                                            )
                                        );
                                    })}
                                </div>
                            </div>
                        </Popover.Content>
                    </Popover>
                )}

                {filter && (
                    <Button
                        {...
                        ({
                            onClick: () => {
                                setOpenDrawer(() => !openDrawer);
                                if (toggleFilters) toggleFilters();
                            }
                        })
                        }
                        variant={'outline'}
                        className={cn(
                            'h-[34px] pe-3 ps-2.5',
                            !isMediumScreen && 'border-dashed border-gray-700'
                        )}
                    >
                        <PiFunnel className="me-1.5 h-[18px] w-[18px]" strokeWidth={1.7} />
                        Filtros
                    </Button>
                )}
            </div>
        </div>
    );
}