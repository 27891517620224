import React, { useCallback } from 'react';
import ProfileLayout from '../TutorProfileLayout';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import { Input, Password, Textarea } from 'rizzui';
import { PiEnvelopeSimple } from 'react-icons/pi';
import FormGroup from '../../../../layout/shared/form-group';
import cn from '../../../../utils/classNames';
import CustomSelect from '../../../../components/forms/CustomSelect';
import { TutorService } from '../../../../services/tutor/tutorService';
import { TutorApiResponse } from '../../../../type/entities/tutor-type';
import { availability, tutorType } from '../../TutorForm';
import PhoneInput from '../../../../components/forms/PhoneInputWrapper';

interface TutorInfoProps { }

const TutorInfo: React.FC<TutorInfoProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const service = new TutorService();

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getTutorById(id);
        return response.getResponseData() as TutorApiResponse;
    }, [id]));

    return (
        <ProfileLayout>
            <div className="@container">
                <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11">
                    <FormGroup
                        title="Datos Personales"
                        description="Información personal del usuario"
                        className="pt-7 @2xl:pt-9 grid @3xl:grid-cols-12 @3xl:pt-11"
                    >
                        <div className="col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <Input
                                disabled
                                label="Nombre"
                                placeholder="First Name"
                                value={data?.name ?? ""}
                                className="flex-grow"
                            />
                            <Input
                                disabled
                                label="Apellidos"
                                placeholder="Last Name"
                                value={data?.lastName ?? ""}
                                className="flex-grow"
                            />
                            <Input
                                disabled
                                label="DNI"
                                placeholder="12345678Z"
                                value={data?.nifCif ?? ""}
                                className="flex-grow"
                            />
                            <Input
                                prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                                type="email"
                                label="Email"
                                placeholder="info@attiva.es"
                                value={data?.email ?? ""}
                                disabled
                            />
                            <CustomSelect
                                id="tutorType"
                                label="Tipo de Tutor"
                                value={{
                                    value: data?.tutorType,
                                    label: tutorType.find((type) => type.value === data?.tutorType)?.label,
                                }}
                                isDisabled
                            />
                            <CustomSelect
                                isMulti
                                id="availability"
                                label="Disponibilidad"
                                value={{
                                    value: data?.availability,
                                    label: data?.availability.map((value: any) =>
                                        availability.find((type) => type.value === value)?.label
                                    ),
                                }}
                                isDisabled
                            />
                            <Input
                                disabled
                                label="Usuario de la Plataforma"
                                placeholder="username"
                                value={data?.platformUser ?? ""}
                                className="flex-grow"
                            />
                            <Password
                                disabled
                                hidden={false}
                                label="Contraseña de la Plataforma"
                                placeholder="********"
                                value={data?.platformPassword ?? ""}
                                className="flex-grow"
                            />
                        </div>
                    </FormGroup>

                    <FormGroup
                        title="Cursos"
                        description="Cursos que el tutor puede impartir"
                        className={cn(
                            "pt-7 @2xl:pt-9 @3xl:grid-cols-12 @3xl:pt-11",
                            data?.products?.length === 0 && "hidden"
                        )}
                    >
                        <div className="col-span-2 grid grid-cols-1 gap-4"> {/* Siempre una columna */}
                            {data?.products?.map((product: any) => (
                                <div key={product.id} className="grid grid-cols-1 md:grid-cols-3 gap-3">
                                    <Input
                                        disabled
                                        label="Curso"
                                        placeholder="Curso"
                                        value={product.product.name}
                                        className="md:col-span-2"
                                    />
                                    <Input
                                        disabled
                                        label="Precio (€)"
                                        placeholder="Precio"
                                        value={product.pricePerStudent}
                                    />
                                </div>
                            ))}
                        </div>
                    </FormGroup>

                    <FormGroup
                        title="Otros datos"
                        description="Información adicional de la cuenta"
                        className="pt-7 @2xl:pt-9 @3xl:grid-cols-12 @3xl:pt-11"
                    >
                        <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                            <PhoneInput
                                id="telephone"
                                disabled
                                label="Teléfono"
                                placeholder="123456789"
                                value={data?.telephone ?? ""}
                                className="flex-grow"
                            />
                            <PhoneInput
                                id="telephoneSecondary"
                                disabled
                                label="Teléfono secundario"
                                placeholder="123456789"
                                value={data?.telephoneSecondary ?? ""}
                                className="flex-grow"
                            />
                            <Input
                                disabled
                                label="Dirección"
                                placeholder="Calle de la forja, 123"
                                value={data?.address ?? ""}
                                className="flex-grow"
                            />
                            <Input
                                disabled
                                label="Población"
                                placeholder="Córdoba"
                                value={data?.town ?? ""}
                                className="flex-grow"
                            />
                            <Input
                                disabled
                                label="Provincia"
                                placeholder="Madrid"
                                value={data?.province ?? ""}
                                className="flex-grow"
                            />
                            <Input
                                disabled
                                label="Código Postal"
                                placeholder="12345"
                                value={data?.postalCode ?? ""}
                                className="flex-grow"
                            />
                            <Textarea
                                id="comments"
                                disabled
                                label="Comentarios"
                                rows={3}
                                className="col-span-1 md:col-span-2 [&>label>span]:font-medium"
                                value={data?.comments}
                            />
                        </div>
                    </FormGroup>
                </div>


                {/* <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11">
                    <FormGroup
                        title="Datos Personales"
                        description='Información personal del usuario'
                        className="pt-7 @2xl:pt-9 @3xl:grid-cols-12 @3xl:pt-11"
                    >
                        <Input
                            disabled
                            label="Nombre"
                            placeholder="First Name"
                            value={data?.name ?? ''}
                            className="flex-grow"
                        />

                        <Input
                            disabled
                            label="Apellidos"
                            placeholder="Last Name"
                            value={data?.lastName ?? ''}
                            className="flex-grow"
                        />

                        <Input
                            disabled
                            label="DNI"
                            placeholder="12345678Z"
                            value={data?.nifCif ?? ''}
                            className="flex-grow"
                        />

                        <Input
                            prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                            type="email"
                            label="Email"
                            placeholder="info@attiva.es"
                            value={data?.email ?? ''}
                            disabled
                        />

                        <CustomSelect
                            id={'tutorType'}
                            label="Tipo de Tutor"
                            value={{ value: data?.tutorType, label: tutorType.find((type) => type.value === data?.tutorType)?.label }}
                            isDisabled
                        />

                        <CustomSelect
                            isMulti
                            id={'availability'}
                            label="Disponibilidad"
                            value={{ value: data?.availability, label: data?.availability.map((value: any) => availability.find((type) => type.value === value)?.label) }}
                            isDisabled
                        />

                        <Input
                            disabled
                            label="Usuario de la Plataforma"
                            placeholder="username"
                            value={data?.platformUser ?? ''}
                            className="flex-grow"
                        />

                        <Password
                            disabled
                            hidden={false}
                            label="Contraseña de la Plataforma"
                            placeholder="********"
                            value={data?.platformPassword ?? ''}
                            className="flex-grow"
                        />
                    </FormGroup>

                    <FormGroup
                        title="Cursos"
                        description="Cursos que el tutor puede impartir"
                        className={cn("pt-7 @2xl:pt-9 @3xl:grid-cols-12 @3xl:pt-11", data?.products?.length === 0 && 'hidden')}
                    >
                        {data?.products?.map((product: any) => (
                            <div key={product.id} className="grid grid-cols-3 gap-3">
                                <Input
                                    disabled
                                    label="Curso"
                                    placeholder="Curso"
                                    value={product.product.name}
                                    className="col-span-2"
                                />

                                <Input
                                    disabled
                                    label="Precio (€)"
                                    placeholder="Precio"
                                    value={product.pricePerStudent}
                                />
                            </div>
                        ))}
                    </FormGroup>

                    <FormGroup
                        title="Otros datos"
                        description="Información adicional de la cuenta"
                        className="pt-7 @2xl:pt-9 @3xl:grid-cols-12 @3xl:pt-11"
                    >
                        <PhoneInput
                            id='telephone'
                            disabled
                            label="Teléfono"
                            placeholder="123456789"
                            value={data?.telephone ?? ''}
                            className="flex-grow"
                        />

                        <PhoneInput
                            id='telephoneSecondary'
                            disabled
                            label="Teléfono secundario"
                            placeholder="123456789"
                            value={data?.telephoneSecondary ?? ''}
                            className="flex-grow"
                        />

                        <Input
                            disabled
                            label="Dirección"
                            placeholder="Calle de la forja, 123"
                            value={data?.address ?? ''}
                            className="flex-grow"
                        />

                        <Input
                            disabled
                            label="Población"
                            placeholder="Córdoba"
                            value={data?.town ?? ''}
                            className="flex-grow"
                        />

                        <Input
                            disabled
                            label="Provincia"
                            placeholder="Madrid"
                            value={data?.province ?? ''}
                            className="flex-grow"
                        />

                        <Input
                            disabled
                            label="Código Postal"
                            placeholder="12345"
                            value={data?.postalCode ?? ''}
                            className="flex-grow"
                        />

                        <Textarea
                            id='comments'
                            disabled
                            label='Comentarios'
                            rows={3}
                            className="col-span-2 [&>label>span]:font-medium"
                            value={data?.comments}
                        />
                    </FormGroup>
                </div> */}
            </div>
        </ProfileLayout>
    );
};

export default TutorInfo;