import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import { UserApiResponse } from '../../../../../type/entities/user-type';
import Page from '../../../../../layout/Page/Page';
import { Loader } from '../../../../../components/loader/SpinnerLogo';
import CustomTable from '../../../../../components/table/CustomTable';
import moment from 'moment';
import { useFiltersPR } from '../../../../../components/providers/FiltersProvider';
import { ChangelogsApiResponse } from '../../../../../type/entities/changelog-type';
import { Link } from 'react-router-dom';
import { Text } from 'rizzui';
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import UserChangelogsFilters from './UserChangelogsFilters';
import { UserService } from '../../../../../services/user/userService';


interface UserChangelogsProps { }

const columnOrder = [
    'id',
    'user',
    'entity',
    'company',
    'description',
    'entityType',
    'createdAt',
];

const UserChangelogs: React.FC<UserChangelogsProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await (new UserService()).listChangelogs(id,filters);
        return response.getResponseData() as UserApiResponse;
    }, [id, filters]));

    return (
        <Page container='fluid' className='mt-5'>
            {loading
                ? <Loader height='70vh' />
                : (
                    <CustomTable
                        id={'changelogs-table'}
                        columnOrder={columnOrder}
                        data={data?.data}
                        columnsNotShown={['id','user','entity','relationModifiedId','entityType']}
                        overrideColumns={[
                            {
                                key: 'user',
                                render: (row: any) => { 
                                    const entity = row.row.original.user;
                                    return (
                                        <Link to={`/users/${entity.id}/edit`}>
                                            <div className="flex items-center gap-3">
                                                <Text>{entity.name + (entity.lastName ? ` ${entity.lastName}` : '')}</Text>
                                            </div>
                                        </Link>
                                    ) 
                                },
                            },
                            {
                                key: 'description',
                                render: (row: any) => { return row.row.original.description },
                            },
                            {
                                key: 'user',
                                render: (row: any) => { return row.row.original.entityType },
                            },
                            {
                                key: 'company',
                                render: (row: any) => { return row.row.original.company?.name },
                            },
                            {
                                key: 'createdAt',
                                render: (row: any) => { return moment(row.row.original.createdAt?.date).format('HH:mm DD/MM/YY') },
                            },
                            {
                                key: 'updatedAt',
                                render: (row: any) => { return moment(row.row.original.updatedAt?.date).format('HH:mm DD/MM/YY') },
                            }
                        ]}
                        actions={[]}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as ChangelogsApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    />
                )}
            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Actividad'}>
                <div className="grid grid-cols-1 gap-6">
                    <UserChangelogsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </Page>
    );
};

export default UserChangelogs;