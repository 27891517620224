import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/product';

export class ProductService extends RestServiceConnection {

    listProducts = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getProductTypes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-types',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getProductById = async (productId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { productId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleProductStatus = async (productId: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { productId, active: status },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }


    createProduct = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: entity,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    editProduct = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: entity,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    deleteProduct = async (productId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { productId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiProducts = async (productsIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { productsIds: productsIds },
        }, true);
        return this;
    }


}