import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/document-types';

export class DocumentTypeService extends RestServiceConnection {

    listDocumentTypes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

}