import { IoArrowDown, IoClose } from "react-icons/io5";
import { Modal, Text, ActionIcon, Accordion, cn } from "rizzui";
import { entities } from "../../../states/StateForm";

interface InfoModalProps {
    isOpen: boolean;
    nodeData: any;
    onClose: () => void;
}

export const InfoModal: React.FC<InfoModalProps> = ({ isOpen, nodeData, onClose }) => {
    if (!nodeData) return null;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size='md'
            overlayClassName='backdrop-blur'
            containerClassName='!max-w-4xl !shadow-2xl'
            className='z-[9999] [&_.pointer-events-none]:overflow-visible'
        >
            <div className='m-auto px-7 pt-6 pb-8'>
                <div className='mb-7 flex items-center justify-between'>
                    <Text className="text-xl">{nodeData.name}</Text>
                    <ActionIcon size='sm' variant='text' onClick={onClose}>
                        <IoClose className='h-auto w-6' strokeWidth={1.8} />
                    </ActionIcon>
                </div>

                <AccordionList nodeData={nodeData} />
            </div>
        </Modal>
    );
};

const AccordionList: React.FC<{ nodeData: any }> = ({ nodeData }) => (
    <>
        <AccordionSection title={`Usuarios (${nodeData.usersCount})`} items={nodeData.users} />
        <AccordionSection title={`Productos (${nodeData.productsCount})`} items={nodeData.products.map((p: any) => p.product)} />
        <AccordionSection title={`Estados visibles (${nodeData.viewStatesCount})`} items={nodeData.viewStates} />
        <AccordionSection title={`Estados modificables (${nodeData.editStatesCount})`} items={nodeData.editStates} />
    </>
);

interface AccordionSectionProps {
    title: string;
    items: any[];
}

const AccordionSection: React.FC<AccordionSectionProps> = ({ title, items }) => (
    <Accordion className='mx-8 border-b last-of-type:border-b-0'>
        <Accordion.Header>
            {({ open }) => (
                <div className='flex w-full cursor-pointer items-center justify-between py-5 text-md font-semibold'>
                    {title}
                    <IoArrowDown
                        className={cn('h-5 w-5 -rotate-90 transform transition-transform duration-300', open && '-rotate-0')}
                    />
                </div>
            )}
        </Accordion.Header>
        <Accordion.Body>
            <ul className='grid grid-cols-1 md:grid-cols-3 gap-1 list-disc pl-4'>
                {items.map((item: any, index: number) => (
                    <li key={index}>
                        <Text>{item.name || `${entities.find(e => e.value === item.statuses.entity)?.label} - ${item.statuses.name}`}</Text>
                    </li>
                ))}
            </ul>
        </Accordion.Body>
    </Accordion>
);